/* You can add global styles to this file, and also import other style files */
@import "bootstrap/scss/bootstrap";
@import "assets/styles/layout";
@import "assets/styles/buttons";
@import "assets/styles/presentation";
@import "assets/styles/progress-tracker";
@import "assets/styles/accordions";

@import "styles-fedex";

.alert {
    border-radius: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
    z-index: 1000;
}

.lh-05 {
    line-height: 0.5 !important;
}

.lh-10 {
    line-height: 1 !important;
}

.lh-15 {
    line-height: 1.5 !important;
}

.lh-20 {
    line-height: 2 !important;
}

.cta-block {
    margin-top: 50px;
}

@media (max-width: 992px) {
    .web-only {
        display: none !important;
    }
}

@media (min-width: 993px) {
    .mobile-tab-only {
        display: none !important;
    }
}

@media (max-width: 490px) {
    .web-tab-only {
        display: none !important;
    }
}

@media (min-width: 491px) {
    .mobile-only {
        display: none !important;
    }

}
 .red-snackbar {
    --mdc-snackbar-container-color: rgb(153, 50, 50);
     color: lightgoldenrodyellow;
 }