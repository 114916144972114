
.presentation-value {
  color: #323647;
  font-family: 'museo-sans-rounded', sans-serif;
  font-weight: 700;
  letter-spacing: 2px !important;
  line-height: 1.6em;
  font-size: 1em;
  text-decoration: none;
}

.presentation-label {
  color: #8a8a8d;
  font-family: 'sofia-pro', sans-serif !important;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: 0.05px;
  line-height: 1.6em;
}

.text-muted {
  &.presentation-value, > .presentation-value, .presentation-value {
    color: lighten(#8a8a8d, 10) !important
  }

  &.presentation-label, > .presentation-label, .presentation-label {
    color: lighten(#8a8a8d, 25) !important;
  }
}

.clickable {
  &:hover {
    cursor: pointer;
    background-color: #fdf7f7;
    //color: #ff0000;
  }
}
