accordion.quotes {
  accordion-group {
    .panel.card {
      border: none;
      background-color: transparent;

      .panel-heading.card-header {
        border: none;
        background-color: transparent;
        padding: 0;
      }

      .accordion-toggle, .panel-collapse {

        &[aria-expanded='true'] {
          //border-bottom: 1px solid #395362;
          margin-bottom: 1em;

          .panel-body.card-block.card-body {
            background-color: #fff;

            @include media-breakpoint-down('md') {
              margin-left: -1em;
              padding-left: 1em;
              margin-right: -1em;
              padding-right: 1em;
            }

            @include media-breakpoint-up('lg') {
              margin-left: -0.5em;
              padding-left: 0.5em;
              margin-right: -1.25em;
              padding-right: 1.25em;
            }
          }
        }

        a.btn {

          &:hover {
            text-decoration: none !important;
            color: var(--primary-highlight);
          }
        }
      }
    }

    /*
    .accordion-body {
      border-top: 1px solid #395362;
      border-bottom: 1px solid #395362;
    }
    */

    /*
    .accordion-body {
      border: 1px solid #395362 !important;
      padding: 1em 0.5em;
    }
    */
  }
}
