.btn {
  &.btn-primary {
    background-color: var(--primary-highlight) !important;
    color: #fff !important;
    border-color: var(--primary-highlight) !important;

    &.active, &:focus, &:hover {
      background-color: var(--primary-highlight-dark) !important;
      border-color: var(--primary-highlight-dark) !important;
      color: #fff !important;
      text-decoration: none !important;
    }
  }

  &.btn-primary-outline {
    background-color: #fff !important;
    color: var(--primary-highlight) !important;
    border-color: var(--primary-highlight) !important;

    &.active, &:focus, &:hover {
      background-color: var(--primary-highlight) !important;
      border-color: var(--primary-highlight) !important;
      color: #fff !important;
      text-decoration: none !important;
    }
  }

  &.btn-secondary {
    background-color: var(--darkblue) !important;
    color: #fff !important;
    border-color: var(--darkblue) !important;

    &.active, &:focus, &:hover {
      background-color: var(--black) !important;
      border-color: var(--black) !important;
      color: #fff !important;
      text-decoration: none !important;
    }
  }

  &.btn-secondary-outline {
    background-color: #fff !important;
    color: var(--darkblue) !important;
    border-color: var(--darkblue) !important;

    &.active, &:focus, &:hover {
      background-color: var(--darkblue) !important;
      border-color: var(--darkblue) !important;
      color: #fff !important;
      text-decoration: none !important;
    }
  }
}

.btn-group {
  .btn {
    &.btn-primary {
      background-color: #fff !important;
      color: var(--primary-highlight) !important;
      border-color: var(--primary-highlight) !important;

      &.active, &:focus {
        background-color: var(--primary-highlight) !important;
        border-color: var(--primary-highlight) !important;
        color: #fff !important;
      }

      &:not(.active):hover {
        border-color: var(--primary-highlight-dark) !important;
        color: var(--primary-highlight-dark) !important;
        text-decoration: none !important;
      }
    }
  }
}

.btn-group {
  .btn {
    &.btn-primary {
      background-color: #fff !important;
      color: var(--secondary-highlight) !important;
      border-color: var(--secondary-highlight) !important;

      &.active, &:focus {
        background-color: var(--secondary-highlight) !important;
        border-color: var(--secondary-highlight) !important;
        color: #fff !important;
      }

      &:not(.active):hover {
        border-color: var(--primary-highlight-dark) !important;
        color: var(--primary-highlight-dark) !important;
        text-decoration: none !important;
      }
    }
  }
}
