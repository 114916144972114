html.fedex {
  --background: #fff;
  --lightblue: #f2f2f2;
  --darkblue: #395362;
  --black: #333333;
  --primary-highlight: #4d148c;
  $primary-highlight: #4d148c;
  --primary-highlight-dark: #411077;
  --secondary-highlight: #ff6200;
  --secondary-highlight-dark: #d85300;
  --loading-border: var(--header-background-color);
  --fedex-font: "Fedex Sans", sans-serif;
  --text-link-blue: #007ab7;
  .cb {
    display: none !important;
  }

  @font-face {
    font-family: "Fedex Sans", sans-serif;
    src: url(assets/fedex/fonts/FedExSans_W_Cd.woff2) format("opentype");
  }

  body,
  .modal-header,
  .modal-footer,
  .modal-body {
    font-family: var(--fedex-font);
    font-weight: 400;
    font-size: 16px;
    color: #666;
    letter-spacing: 0.05px;
    line-height: 25px;
  }

  .modal-header {
    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 1rem;
    }
  }

  .cdk-global-overlay-wrapper,
  .cdk-overlay-container {
    z-index: 9999 !important;
  }

  .modal-dialog {
    // not including .modal.show to keep the view from changing sizes during hide animation
    width: 80%;
    min-width: 350px;
    max-width: 850px;
    margin: auto;
  }

  .modal.show .modal-dialog {
    position: relative;
    top: 10%;

    & .modal-content {
      border-radius: 0;
    }

    &.view-entire-library {
      width: 55%;
    }

    &.recommendations-modal {
      width: 80%;
    }
  }

  sup,
  sub {
    font-size: 12px;
  }

  .body {
    font-family: var(--fedex-font);
    font-weight: 400;
    font-size: 16px;
    color: #666;
    letter-spacing: 0.05px;
    line-height: 25px;
  }

  pre {
    background-color: #eee;
    border: 1px solid #8a8a8d;
    color: #8a8a8d;
    padding: 10px;
    margin: 10px 0;
  }

  h1 {
    font-family: var(--fedex-font);
    font-weight: 300;
    font-size: 40px;
    color: var(--black);
    letter-spacing: 1px;
    line-height: 48px;
    margin-top: 0;
    text-decoration: none;
  }

  h2 {
    font-family: var(--fedex-font);
    font-weight: 500;
    font-size: 1.875em;
    color: var(--black);
    letter-spacing: 0.75px;
    line-height: 36px;
    margin-top: 0;
    text-decoration: none;
  }

  h3 {
    font-family: var(--fedex-font);
    font-weight: 500;
    font-size: 1.5em;
    color: var(--black);
    letter-spacing: 0.6px;
    line-height: 30px;
    margin-top: 0;
    text-decoration: none;
  }

  h4 {
    font-family: var(--fedex-font);
    font-weight: 500;
    font-size: 1.25em;
    color: var(--black);
    letter-spacing: 0.5px;
    line-height: 26px;
    margin-top: 0;
    text-decoration: none;
  }

  b.title,
  h5 {
    font-family: var(--fedex-font);
    font-weight: 500;
    font-size: 1em;
    color: var(--black);
    letter-spacing: 1px;
    line-height: 20px;
    margin-top: 0;
    text-decoration: none;
  }

  h6 {
    font-family: var(--fedex-font);
    font-weight: 500;
    font-size: 0.8em;
    color: var(--black);
    letter-spacing: 2px;
    line-height: 16px;
    margin-top: 0;
    text-decoration: none;
  }

  a {
    color: var(--text-link-blue );
    font-family: var(--fedex-font);
    font-weight: 900;
    cursor: pointer;

    &:hover {
      color: darken($primary-highlight, 15);
      text-decoration: underline;
    }

    &.disabled {
      //color: $primary-highlight, 15) !important;
      color: #ccc;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  li {
    font-family: var(--fedex-font);
    font-weight: 400;
    font-size: 16px;
    color: #666;
    letter-spacing: 0.05px;
    line-height: 25px;
    padding-left: 0;
    text-indent: -15px;
    padding-bottom: 5px;
  }

  a,
  .btn-link {
    color: var(--primary-highlight);
  }

  svg {
    &.svg-inline--fa {
      &.red {
        > path {
          color: var(--primary-highlight);
        }
      }
    }
  }

  i {
    &.far,
    &.fal,
    &.fas {
      &.red {
        color: var(--primary-highlight);
      }
    }
  }

  .page-title {
    text-align: left;
    padding: 48px 0;

    &.eyebrow {
      > h1,
      > h2,
      > h3,
      > h4,
      > h5 {
        margin-bottom: 0.5em;

        &.text-muted {
          &:after {
            border-bottom-color: lighten($primary-highlight, 15);
          }
        }
      }
      &:after {
        display: block;
        border-bottom: 3px solid var(--primary-highlight);
        width: 45px;
        content: "";
        margin: 0 auto;
        margin-top: 8px;
        margin-bottom: 18px;
        padding: 3px 0;
      }
    }

    &.inline {
      text-align: inherit;

      &.eyebrow {
        > h1,
        > h2,
        > h3,
        > h4,
        > h5 {
          margin-bottom: 0.5em;

          &:after {
            margin: inherit;
          }
        }
      }
    }
  }

  .link-container {
    position: relative;
    display: inline;
    text-transform: uppercase;
    font-weight: 500;
    font-family: var(--fedex-font);
    letter-spacing: 2px !important;
    line-height: 33px;
    font-size: 16px;
    padding: 0.5em 1em;
    white-space: nowrap;

    &.chevron-anchor {
      > a {
        margin-left: 1px;
        text-decoration: none !important;
      }

      &:before,
      &:after {
        display: block;
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        width: 8px;
        background-repeat: no-repeat;
        background-position: 0 50%;
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }

      &.red {
        > a {
          color: #007ab7;

          &:hover {
            color: #007ab7 !important;
          }
        }

        &:hover {
          color: #007ab7;
          cursor: pointer;
        }

        &.border {
          border: 2px solid #007ab7 !important;
          padding: 8px 25px;
          border-radius: 40px;

          &:after {
            margin-right: 10px;
          }

          &:before {
            margin-left: 10px;
          }
        }
      }

      &.white {
        > a {
          color: #fff;
        }

        &:after {
          background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12"><defs><style>.a{fill:%23fff;}</style></defs><path class="a" d="M0,1.408A1.5,1.5,0,0,0-.366.421a1.192,1.192,0,0,0-1.793,0L-6,4.635-9.837.407a1.192,1.192,0,0,0-1.793,0,1.5,1.5,0,0,0,0,1.975L-6.9,7.6A1.194,1.194,0,0,0-6,8a1.245,1.245,0,0,0,.9-.4L-.366,2.4A1.53,1.53,0,0,0,0,1.408Z" transform="translate(8 12) rotate(90)"/></svg>');
        }

        &:before {
          background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12"><defs><style>.a{fill:%23fff;}</style></defs><path class="a" d="M0,1.408A1.5,1.5,0,0,1,.366.421a1.192,1.192,0,0,1,1.793,0L6,4.635,9.837.407a1.192,1.192,0,0,1,1.793,0,1.5,1.5,0,0,1,0,1.975L6.9,7.6A1.194,1.194,0,0,1,6,8a1.245,1.245,0,0,1-.9-.4L.366,2.4A1.53,1.53,0,0,1,0,1.408Z" transform="translate(0 12) rotate(-90)"/></svg>');
        }

        &.border {
          border: 2px solid white !important;
          padding: 5px 25px;

          &:after {
            margin-right: 10px;
          }

          &:before {
            margin-left: 10px;
          }
        }
      }

      &.border {
        &.disabled {
          border: 2px solid #8a8a8d !important;

          &:before,
          &:after {
            -webkit-transition: none;
            -o-transition: none;
            transition: none;
          }

          a {
            color: #8a8a8d !important;
            cursor: not-allowed;
          }
        }
      }

      &:hover {
        background-color: transparent;
        border-color: transparent;

        &:before,
        &:after {
          @include transition(all 0.1s ease-in-out);
        }

        &:before {
          -webkit-transform: translateX(4px);
          -ms-transform: translateX(4px);
          transform: translateX(4px);
        }

        &:after {
          -webkit-transform: translateX(-4px);
          -ms-transform: translateX(-4px);
          transform: translateX(-4px);
        }
      }

      &.small {
        font-weight: 500;
        position: relative;
        display: inline;
        font-family: var(--fedex-font);
        letter-spacing: 2px !important;
        line-height: 28px;
        font-size: 0.75em;
        padding: 0.5em 1em;
        text-decoration: none;

        &:before {
          left: 0;
          width: 6px;
        }

        &:after {
          right: 0;
          width: 6px;
        }

        &.red {
          &:before {
            background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12"><defs><style>.a{fill:%23D53E30;}</style></defs><path class="a" d="M0,1.408A1.5,1.5,0,0,1,.366.421a1.192,1.192,0,0,1,1.793,0L6,4.635,9.837.407a1.192,1.192,0,0,1,1.793,0,1.5,1.5,0,0,1,0,1.975L6.9,7.6A1.194,1.194,0,0,1,6,8a1.245,1.245,0,0,1-.9-.4L.366,2.4A1.53,1.53,0,0,1,0,1.408Z" transform="translate(0 12) rotate(-90)"/></svg>');
          }

          &:after {
            background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12"><defs><style>.a{fill:%23D53E30;}</style></defs><path class="a" d="M0,1.408A1.5,1.5,0,0,0-.366.421a1.192,1.192,0,0,0-1.793,0L-6,4.635-9.837.407a1.192,1.192,0,0,0-1.793,0,1.5,1.5,0,0,0,0,1.975L-6.9,7.6A1.194,1.194,0,0,0-6,8a1.245,1.245,0,0,0,.9-.4L-.366,2.4A1.53,1.53,0,0,0,0,1.408Z" transform="translate(8 12) rotate(90)"/></svg>');
          }
        }

        &.white {
          &:after {
            background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12"><defs><style>.a{fill:%23fff;}</style></defs><path class="a" d="M0,1.408A1.5,1.5,0,0,0-.366.421a1.192,1.192,0,0,0-1.793,0L-6,4.635-9.837.407a1.192,1.192,0,0,0-1.793,0,1.5,1.5,0,0,0,0,1.975L-6.9,7.6A1.194,1.194,0,0,0-6,8a1.245,1.245,0,0,0,.9-.4L-.366,2.4A1.53,1.53,0,0,0,0,1.408Z" transform="translate(8 12) rotate(90)"/></svg>');
          }

          &:before {
            background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12"><defs><style>.a{fill:%23fff;}</style></defs><path class="a" d="M0,1.408A1.5,1.5,0,0,1,.366.421a1.192,1.192,0,0,1,1.793,0L6,4.635,9.837.407a1.192,1.192,0,0,1,1.793,0,1.5,1.5,0,0,1,0,1.975L6.9,7.6A1.194,1.194,0,0,1,6,8a1.245,1.245,0,0,1-.9-.4L.366,2.4A1.53,1.53,0,0,1,0,1.408Z" transform="translate(0 12) rotate(-90)"/></svg>');
          }
        }
      }

      &.disabled {
        cursor: not-allowed;

        > a {
          color: #8a8a8d;
          cursor: not-allowed;
        }

        &:before,
        &:after {
          display: block;
          position: absolute;
          content: "";
          top: 0;
          bottom: 0;
          background-repeat: no-repeat;
          background-position: 0 50%;
        }

        &:before {
          left: 0;
          background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12"><defs><style>.a{fill:%238A8A8D;}</style></defs><path class="a" d="M0,1.408A1.5,1.5,0,0,1,.366.421a1.192,1.192,0,0,1,1.793,0L6,4.635,9.837.407a1.192,1.192,0,0,1,1.793,0,1.5,1.5,0,0,1,0,1.975L6.9,7.6A1.194,1.194,0,0,1,6,8a1.245,1.245,0,0,1-.9-.4L.366,2.4A1.53,1.53,0,0,1,0,1.408Z" transform="translate(0 12) rotate(-90)"/></svg>');
        }

        &:after {
          right: 0;
          background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12"><defs><style>.a{fill:%238A8A8D;}</style></defs><path class="a" d="M0,1.408A1.5,1.5,0,0,0-.366.421a1.192,1.192,0,0,0-1.793,0L-6,4.635-9.837.407a1.192,1.192,0,0,0-1.793,0,1.5,1.5,0,0,0,0,1.975L-6.9,7.6A1.194,1.194,0,0,0-6,8a1.245,1.245,0,0,0,.9-.4L-.366,2.4A1.53,1.53,0,0,0,0,1.408Z" transform="translate(8 12) rotate(90)"/></svg>');
        }

        &:hover {
          > a {
            color: #8a8a8d;
            cursor: not-allowed;
          }

          &:after,
          &:before {
            -webkit-transform: none !important;
            -ms-transform: none !important;
            transform: none !important;
          }
        }
      }
    }
  }

  button {
    background-color: transparent;
    box-shadow: none;
    border: 0;
  }

  .red-submit {
    font-family: var(--fedex-font);
    font-weight: bold;
    font-size: 19px;
    color: white;
    letter-spacing: 1px;
    text-align: center;
    line-height: 33px;
    background-color: var(--primary-highlight);
    padding: 8px 30px;
    box-shadow: none;
  }
  .white-submit {
    font-family: var(--fedex-font);
    font-weight: bold;
    font-size: 19px;
    color: var(--primary-highlight);
    letter-spacing: 1px;
    text-align: center;
    line-height: 33px;
    border: 2px solid var(--primary-highlight);
    padding: 8px 30px;
    background-color: transparent;
    box-shadow: none;

    &:hover,
    &:active {
      background-color: var(--primary-highlight);
      color: white;
    }

    &.disabled {
      color: #ccc;
      border: 2px solid #ccc;

      &:hover {
        cursor: default;
        background-color: transparent;
      }
    }
  }
  .orange-submit {
    font-family: var(--fedex-font);
    font-weight: bold;
    font-size: 19px;
    color: #fff;
    letter-spacing: 1px;
    text-align: center;
    line-height: 33px;
    border: 2px solid #ff6200;
    padding: 8px 30px;
    background-color: #ff6200;
    box-shadow: none;

    &.disabled,
    &:disabled {
      color: #666;
      background-color: transparent;
      border: 2px solid #ccc;

      &:hover {
        cursor: default;
      }
    }
  }
  .orange-outline {
    font-family: var(--fedex-font);
    font-weight: bold;
    font-size: 19px;
    color: #ff6200;
    letter-spacing: 1px;
    text-align: center;
    line-height: 33px;
    border: 2px solid #ff6200;
    padding: 8px 30px;
    background-color: transparent;
    box-shadow: none;

    &.disabled,
    &:disabled {
      color: #666;
      background-color: transparent;
      border: 2px solid #ccc;

      &:hover {
        cursor: default;
      }
    }
  }

  .pac-container {
    z-index: 99999 !important;
  }
  .white1-submit {
    font-family: var(--fedex-font);
    font-weight: 900;
    font-size: 1em;
    color: var(--primary-highlight);
    margin-right: 178px;
    letter-spacing: 1px;
    text-align: center;
    line-height: 33px;
    border: 2px solid var(--primary-highlight);
    padding: 8px 30px;
    background-color: transparent;
    box-shadow: none;

    &:hover,
    &:active {
      background-color: var(--primary-highlight);
      color: white;
    }

    &.disabled {
      color: #ccc;
      border: 2px solid #ccc;

      &:hover {
        cursor: default;
        background-color: transparent;
      }
    }
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-error {
    display: none !important;
  }
  .mat-form-field-label-wrapper {
    width: 0 !important;
    height: 0 !important;
  }
  .modal-content {
    .modal-header {
      padding-top: 45px;
    }
    .modal-body {
      text-align: center;
      padding-bottom: 30px;
      margin-bottom: 0;
    }

    .page-title {
      text-align: center;
      width: 100%;
      h1 {
        font-size: 40px;
      }
    }

    .close-button {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 0;

      img {
        width: 30px;
        height: 30px;
      }
    }
    .button-container {
      text-align: center;
      margin-top: 20px;
    }
  }

  .no-scroll {
    margin: 0;
    height: 110%;
    overflow: hidden;
  }

  .input-group-text {
    background-color: #c2b8b7;
    color: #fff;
  }

  .has-badge {
    width: 2em;
    height: 1.5em;

    > svg,
    > i {
      margin: 4px 8px;
    }

    &[data-count] {
      > svg,
      > i {
        width: 1.25em;
        height: 1em;

        @include media-breakpoint-down("md") {
          width: 0.75em;
          height: 0.9em;
        }
      }

      @include media-breakpoint-down("md") {
        width: 1.75em;
        height: 1.5em;

        &:after {
          font-size: 20%;
          font-weight: normal;
          padding: 0.3em;
        }
      }

      &:after {
        position: absolute;
        right: 0;
        top: -5px;
        content: attr(data-count);
        font-size: 30%;
        padding: 0.6em;
        border-radius: 999px;
        line-height: 0.75em;
        color: white;
        text-align: center;
        min-width: 2em;
        font-weight: bold;
        background: #ff6200;
      }

      &.gray {
        &:after {
          background: #c2b8b7;
        }
      }
    }
  }

  .expand {
    margin-left: -1.5em !important;
    padding-left: 1.5em !important;
    margin-right: -1.5em !important;
    padding-right: 1.5em !important;
  }

  .expand-left {
    margin-left: -1.5em !important;
    padding-left: 1.5em !important;
  }

  .expand-right {
    margin-right: -1.5em !important;
    padding-right: 1.5em !important;
  }

  .expand-none {
    margin-left: 0 !important;
    padding-left: 0.5em !important;
    margin-right: 0 !important;
    padding-right: 0.5em !important;
  }

  .expand-none-left {
    margin-left: 0 !important;
    padding-left: 0.5em !important;
  }

  .expand-none-right {
    margin-right: 0 !important;
    padding-right: 0.5em !important;
  }

  @include media-breakpoint-up("md") {
    .expand-md-none {
      margin-left: 0 !important;
      padding-left: 0.5em !important;
      margin-right: 0 !important;
      padding-right: 0.5em !important;
    }

    .expand-md-none-left {
      margin-left: 0 !important;
      padding-left: 0.5em !important;
    }

    .expand-md-none-right {
      margin-right: 0 !important;
      padding-right: 0.5em !important;
    }

    .expand-md {
      margin-left: -1.5em !important;
      padding-left: 1.5em !important;
      margin-right: -1.5em !important;
      padding-right: 1.5em !important;
    }

    .expand-md-left {
      margin-left: -1.5em !important;
      padding-left: 1.5em !important;
    }

    .expand-md-right {
      margin-right: -1.5em !important;
      padding-right: 1.5em !important;
    }
  }

  @include media-breakpoint-up("lg") {
    .expand-lg-none {
      margin-left: 0 !important;
      padding-left: 0.5em !important;
      margin-right: 0 !important;
      padding-right: 0.5em !important;
    }

    .expand-lg-none-left {
      margin-left: 0 !important;
      padding-left: 0.5em !important;
    }

    .expand-lg-none-right {
      margin-right: 0 !important;
      padding-right: 0.5em !important;
    }

    .expand-lg {
      margin-left: -1.5em !important;
      padding-left: 1.5em !important;
      margin-right: -1.5em !important;
      padding-right: 1.5em !important;
    }

    .expand-lg-left {
      margin-left: -1.5em !important;
      padding-left: 1.5em !important;
    }

    .expand-lg-right {
      margin-right: -1.5em !important;
      padding-right: 1.5em !important;
    }
  }

  .mw-25 {
    max-width: 25% !important;
  }

  .mw-50 {
    max-width: 50% !important;
  }

  .mw-75 {
    max-width: 75% !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .text-linethrough {
    text-decoration: line-through;
  }

  .text-success {
    color: #69b38a !important;
  }

  .text-danger {
    color: var(--primary-highlight) !important;
  }

  html,
  body {
    height: 100%;
  }

  body {
    margin: 0;
    font-family: var(--fedex-font);
  }

  .bread-crumbs-container {
    padding-left: 48px;
    height: 40px;
    display: flex;
    align-items: center;

    a {
      font-family: var(--fedex-font) !important;
      font-weight: 400;
      font-size: 12px;
      color: #333;
      letter-spacing: 0.25px;
      line-height: 13px;

      img {
        margin-top: -3px;
        margin-right: 5px;
      }

      &.light {
        font-weight: 400;
      }
    }

    a:hover {
      cursor: pointer;
    }

    .current-page {
      font-family: var(--fedex-font) !important;
      font-weight: 500;
      font-size: 0.875em;
      color: #666;
      letter-spacing: 0.25px;
      line-height: 13px;

      img {
        margin-top: -3px;
        margin-right: 5px;
        margin-left: 7px;
      }

      &.light {
        font-weight: 400;
      }
    }
  }

  alert.banner {
    div.alert-info {
      border: 0;
      background-color: #ebf0f3;
      color: var(--primary-highlight);
      line-height: 1.5;
    }
  }

  .purple-background {
    background: var(--primary-highlight);
  }

  .cta-block {
    padding: 54px 30px 47px 30px;
    text-align: center;
    position: relative;

    &.tan-background {
      background: #f3f0ed;
    }

    &.white-background {
      background: #ffffff;
    }

    &.purple-background {
      background: var(--primary-highlight);
    }

    .cta-background-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    h2 {
      text-align: center;
    }

    h5 {
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  html,
  body {
    height: 100%;
    min-height: 100%;
  }
  body {
    margin: 0;
    font-family: var(--fedex-font);
  }

  .page-container {
    position: relative;
    }
  .content-container {
    height: 100%;
  }
  .background-container {
    min-height: calc(100vh - 64px - 48px - 40px);
  }

  .mat-select-panel-wrap {
    flex-basis: calc(100% - 22px) !important;
  }

  .mat-select-panel {
    border-radius: 0 !important;
    max-height: 302px !important;
  }

  .cdk-overlay-pane {
    transform: translateX(-10px) translateY(38px) !important;
  }

  .mat-select-arrow {
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
    margin-top: 4px !important;
    margin-right: 8px !important;
    width: 12px !important;
    height: 8px !important;
    background-image: url("assets/cb/images/arrow_down.svg") !important;
  }
}
.approval-container {
  .mdc-checkbox .mdc-checkbox__background {
    border: 1px solid lightgrey;
    background-color: white;
  }
  .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background {
    border: 1px solid var(--primary-highlight);
    background-color: var(--primary-highlight);
  }
  .mdc-label {
    font-size: 0.75rem;
  }
}
