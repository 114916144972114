@import "bootstrap/scss/bootstrap";

.top-nav {
  background-color: #fff;

}

.sidebar {
  //min-height: calc(100vh - 100px);
  min-height: 100%;
}

section.content {
  min-height: calc(100vh - 135px);
}

.menu-toggle {
  display: none;
}

@include media-breakpoint-down(md) {

  .menu-toggle {
    display: inherit;

    &:focus {
      outline: none;
    }

  }
}

.dropdown {
  .dropdown-item {
    &:hover {
      background-color: var(--background);
      color: var(--primary-highlight) !important;
      text-decoration: none !important;
    }
    &.active, &:active {
      background-color: var(--primary-highlight);
      color: #fff !important;
    }
  }
}


ng-sidebar {
  aside {
    background-color: #fff;

    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 1.44px;
    line-height: 28px;

    .nav-item {
      > .nav-link {
        font-size: 1em;
        text-transform: uppercase;
        padding: 0 1rem;

        &.active {
          text-decoration: underline;
        }

        @include media-breakpoint-up(md) {
          &:focus {
            outline: none;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      width: 100%;

      .nav-item {
        text-align: center;

        &.radio-button {
          padding-left: 0;
        }

        .nav-link {
          font-size: 1.4em;

          &.active {
            text-decoration: underline;
          }

          input[type='radio'], .checkmark {
            display: none;
          }
        }
      }

      .sidebar-heading {
        > span {
          margin: 0 auto;
        }
      }
    }

  }
}

footer {

  .copyright {
    font-size: 75%;
  }
}


